import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NteCheckboxIcon } from '../../../theme/assets/nte-checkbox.svg';
import { ReactComponent as NteCheckboxChecked } from '../../../theme/assets/nte-checked-box.svg';
import { formatAriaLabel } from '../../../utils/utils';

interface INteCheckbox {
  /**
   * @prop value for the checkbox.
   */
  value?: string;

  /**
   * @prop labelPlacement anchor for the checkbox.
   */
  labelPlacement: FormControlLabelProps['labelPlacement'];

  /**
   * @prop label for the checkbox.
   */
  label: React.ReactNode | string;

  /**
   * @prop onChange handles the checkbox toggle states.
   */
  onChange?: SwitchBaseProps['onChange'];

  /**
   * @prop checked flag determines whether the checkbox is
   * checked or not.
   */
  checked: boolean;

  /**
   * @prop id for the checkbox.
   */
  id: string;

  /**
   * @prop disabled Disables the checkbox.
   */
  disabled?: boolean;

  /**
   * @prop callback for Enter key press.
   */
  onKeyDown?: (e: any) => void;
}

/**
 * @component NteCheckbox renders the checkbox with label and allows the label
 * placement at top, bottom, start and end of the checkbox.
 *
 * @param INteCheckbox
 */
const NteCheckbox: React.FC<INteCheckbox> = ({
  onChange,
  checked,
  disabled,
  label,
  id,
  ...formControlLabelProps
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          inputProps={{
            'aria-label': formatAriaLabel(
              typeof label === 'string' ? t(label) : ''
            ),
          }}
          checkedIcon={
            <NteCheckboxChecked role='img' aria-label='checkbox icon checked' />
          }
          icon={<NteCheckboxIcon role='img' aria-label='checkbox icon' />}
        />
      }
      label={typeof label === 'string' ? t(label) : label}
      className='checkbox-wrapper'
      id={id}
      {...formControlLabelProps}
    />
  );
};

export { NteCheckbox };
