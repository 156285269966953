import { AxiosError, AxiosRequestConfig } from 'axios';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../../redux/actions/network-errors.actions';
import {
  INIT_SITE_FAILURE_ACTION,
  INIT_SITE_SUCCESS_ACTION,
} from '../../../redux/actions/site';
import { trackUserEvent } from '../../../utils/lucky-orange';
import { makeUnauthorizedRequest } from '../../apis/axios/axiosConfig';
import { site } from '../../constants/site';
import IOnlineStoreResponse from '../../interface/Responses/IOnlineStoreResponse';
import validateIOnlineStoreResponse from '../../interface/Responses/IOnlineStoreResponse.validator';
import IStoreDataResponse from '../../interface/Responses/IStoreDataResponse';
import validateIStoreDataResponse from '../../interface/Responses/IStoreDataResponse.validator';

/**
 * @interface SiteInfoArgs - Defines the intial Site info fetched from .env file.
 */
export interface SiteInfoArgs {
  /**
   * StoreName to contact the backend.
   */
  storeName?: string;

  /**
   * Url prefix for Search operations.
   */
  searchContext: string;

  /**
   * Url prefix for Transaction operations(Espot).
   */
  transactionContext?: string;

  /**
   * Store Id to contact backend.
   */
  storeID?: string;

  /**
   * Other related information.
   */
  [extraPros: string]: any;

  /**
   * Extracts the inventory count of the current product.
   */
  onlineInventory?: string;

  globalBopis?: string;

  /**
   * storeLocatorDefaultRadius for Find my store
   */
  storeLocatorDefaultRadius: string;

  /**
   * storeLocatorResponseCount for Find my store
   */
  storeLocatorResponseCount: string;

  /**
   * inventoryThreshold for stock status
   */
  inventoryThreshold: string;

  /**
   * advantage Promo Flag
   */
  showAdvantagePromo: string;

  /**
   * maxLineItems limit
   */
  maxLineItems: string;

  /**
   * maxQuantity limit
   */
  maxQuantity: string;

  /**
   * soldinstoreAttributeId
   */
  soldinstoreAttributeId: string;

  /**
   * promotionAttributeId
   */
  promotionAttributeId: string;
}

/**
 * @interface SiteInfo - Defines the site information needed to contact the backend.
 */
export interface SiteInfo {
  /**
   * StoreName to contact the backend.
   */
  storeName: string;

  /**
   * Store Id to contact backend.
   */
  storeID: string;

  /**
   * Catalog Id to contact backend.
   */
  catalogID: string;

  /**
   * Catalog Store Id to contact backend.
   */
  catalogStoreID: string;

  /**
   * Url prefix for Search operations.
   */
  searchContext: string;

  /**
   * Store Type to contact backend.
   */
  storeType: string;

  /**
   * Microsoft Validate key.
   */
  msValidateKey: string;

  /**
   * Site Tag Line.
   */
  siteTagline: string;

  /**
   * Contract id.
   */
  contractID: string;

  /**
   * Product count per Page on PLP pages..
   */
  productCountPerPage: number;

  /**
   * storeLocatorDefaultRadius for Find my store
   */
  storeLocatorDefaultRadius: string;

  /**
   * storeLocatorResponseCount for Find my store
   */
  storeLocatorResponseCount: string;

  /**
   * inventoryThreshold for stock status
   */
  inventoryThreshold: string;

  /**
   * advantage Promo Flag
   */
  showAdvantagePromo: string;

  /**
   * maxLineItems limit
   */
  maxLineItems: string;

  /**
   * maxQuantity limit
   */
  maxQuantity: string;

  /**
   * bvExcludedItems
   */
  bvExcludedItems: string[];

  /**
   * categoriesWithIndependentAttributes
   */
  categoriesWithIndependentAttributes: string[];

  /**
   * brandMenuItemLimit
   */
  brandMenuItemLimit: string;

  /**
   * soldinstoreAttributeId
   */
  soldinstoreAttributeId: string;

  /**
   * promotionAttributeId
   */
  promotionAttributeId: string;

  /**
   * allDealsNonAdvantageFilter
   */
  allDealsNonAdvantageFilter: string;

  /**
   * allDealsAdvantageFilter
   */
  allDealsAdvantageFilter: string;

  /**
   * triggerProductSearchSuggestion
   */
  triggerProductSearchSuggestion: string;

  /**
   * triggerCategorySearchSuggestion
   */
  triggerCategorySearchSuggestion: string;
  /**
   * debounce time limit
   */
  searchDebounceTime: string;

  /**
   * enable or disable rvi site wide
   */
  enableRVISiteWide: string;

  /**
   * enable or disable toggle for gird view
   */
  enablePlpGridView: string;

  /**
   * enable or disable toggle for BOPIS
   */
  disablePayPalPayment: string;

  enableGiftCardPaymentOptions: string;

  defaultPlpView: string;

  enableIOMPaymentOptions: string;

  enablePaypalPaymentOptions: string;

  enableNET30PaymentOptions: string;

  poNumberRegex: string;

  dealPages: string;

  clearanceFilter: string;

  /**
   * Attribute ID for filtering by the Collections custom PLP pages. Used in PLPLayoutHooks.
   */
  collectionsPlpFacetKey: string;
  /**
   * Attribute ID for filtering by the Promotions custom PLP pages. Used in PLPLayoutHooks.
   */
  promotionPlpFacetKey: string;
  [key: string]: string | string[] | number;
}

/**
 * @class SiteInfoService to maintain information to contact the backend.
 */
export class SiteInfoService {
  private static mySiteInfo: SiteInfoService = new SiteInfoService();

  private siteInfo: SiteInfo | null = null;

  public static getSiteInfo(): SiteInfoService {
    return SiteInfoService.mySiteInfo;
  }

  public getSiteValue(): SiteInfo | null {
    return this.siteInfo;
  }

  public async setSite(s: SiteInfoArgs, dispatch: any) {
    if (!this.siteInfo) {
      try {
        this.siteInfo = await this.initializeSite(s);

        dispatch(INIT_SITE_SUCCESS_ACTION(this.siteInfo));
      } catch (err) {
        const { response }: AxiosError = err as AxiosError;

        trackUserEvent({
          eventMsg: response,
          eventName: 'tier 1 error',
        });

        dispatch(INIT_SITE_FAILURE_ACTION());

        dispatch(
          REDIRECT_TO_ERROR_PAGE_ACTION({
            errorCode: response?.status.toString(),
            errorKey: response?.config.url,
            errorMessage: response?.data,
            redirectToErrorPage: true,
          })
        );
      }
    }
  }

  /**
   * @method initalizeSite - Initializes all the information to contact the backend..
   * @param siteInfo - Contains the Initial Site info fetched from .env file.
   * @returns site store information that is needed to contact the backend.
   */
  private async initializeSite(
    siteInfo: SiteInfoArgs
  ): Promise<SiteInfo | any> {
    const _site: SiteInfoArgs = { ...siteInfo };
    try {
      const {
        DEALS_EVERY_DAY_SHIPPING_FILTER,
        DEALS_NEW_PRODUCTS,
        DEALS_ON_SALE,
        DEALS_REBATES_SPECIAL_OFFER,
        DEALS_ADVANTAGE_EXCLUSIVE,
      } = site;

      const siteStoreData = await this.getStoreData({ ..._site });
      _site.storeID = siteStoreData.storeId;
      _site.inventorySystem = siteStoreData.inventorySystem;
      _site.storeName = siteStoreData.storeName;
      _site.catalogID = siteStoreData.defaultCatalogId;

      const storeConfig: any = await this.getOnlineStoreData({
        ..._site,
      });

      const isAllDealsPresent =
        Boolean(storeConfig?.userData?.allDealsAdvantageFilter) ||
        Boolean(storeConfig?.userData?.allDealsNonAdvantageFilter);

      if (!isAllDealsPresent) {
        console.error('All Deals filter config is not available.');
      }

      _site.googleSiteVerificationId =
        storeConfig.userData['google-site-verification'];
      _site.msValidateKey = storeConfig.userData['msvalidate.01'];
      _site.siteTagline = storeConfig.userData?.tagline;
      _site.productCountPerPage = storeConfig.userData?.productCountPerPage;
      _site.onlineInventory = storeConfig?.userData?.onlineInventory;
      _site.globalBopis = storeConfig?.userData?.globalBopis;
      _site.inventoryThreshold = storeConfig?.userData?.inventoryThreshold;
      _site.triggerProductSearchSuggestion = storeConfig?.userData
        ?.triggerProductSearchSuggestion
        ? storeConfig?.userData?.triggerProductSearchSuggestion
        : _site.triggerProductSearchSuggestion;
      _site.triggerCategorySearchSuggestion = storeConfig?.userData
        ?.triggerCategorySearchSuggestion
        ? storeConfig?.userData?.triggerCategorySearchSuggestion
        : _site.triggerCategorySearchSuggestion;
      _site.showAdvantagePromo = storeConfig?.userData?.advantagePromotionFlag;
      _site.maxLineItems = storeConfig?.userData?.maxLineItems;
      _site.maxQuantity = storeConfig?.userData?.maxQuantity;
      _site.bvExcludedItems =
        storeConfig?.userData?.bvExcludedItems?.split(',');
      _site.categoriesWithIndependentAttributes =
        storeConfig?.userData?.categoriesWithIndependentAttributes?.split('|');
      _site.brandMenuItemLimit = storeConfig?.userData?.brandMenuItemLimit;
      _site.promotionAttributeId = storeConfig?.userData?.promotionAttributeId;
      _site.soldinstoreAttributeId =
        storeConfig?.userData?.soldinstoreAttributeId;
      _site.allDealsNonAdvantageFilter =
        storeConfig?.userData?.allDealsNonAdvantageFilter;
      _site.allDealsAdvantageFilter =
        storeConfig?.userData?.allDealsAdvantageFilter;
      _site.searchDebounceTime = storeConfig?.userData?.searchDebounceTime
        ? storeConfig?.userData?.searchDebounceTime
        : _site.searchDebounceTime;
      _site.enableRVISiteWide = storeConfig?.userData?.enableRVISiteWide
        ? storeConfig?.userData?.enableRVISiteWide
        : _site.enableRVISiteWide;
      _site.enablePlpGridView = storeConfig?.userData?.enablePlpGridView
        ? storeConfig?.userData?.enablePlpGridView
        : _site.enablePlpGridView;
      _site.disablePayPalPayment = storeConfig?.userData?.disablePayPalPayment
        ? storeConfig?.userData?.disablePayPalPayment
        : _site.disablePayPalPayment;
        _site.enableGiftCardPaymentOptions = storeConfig?.userData?.enableGiftCardPaymentOptions
        ? storeConfig?.userData?.enableGiftCardPaymentOptions
        : _site.enableGiftCardPaymentOptions;
      _site.defaultPlpView = storeConfig?.userData?.defaultPlpView
        ? storeConfig?.userData?.defaultPlpView
        : _site.defaultPlpView;
      _site.poNumberRegex = storeConfig?.userData?.PO_NUMBER_REGEX
        ? storeConfig?.userData?.PO_NUMBER_REGEX
        : _site.poNumberRegex;
      _site.dealPages = storeConfig?.userData?.dealPages;
      _site.enableIOMPaymentOptions = storeConfig?.userData
        ?.enableIOMPaymentOptions
        ? storeConfig?.userData?.enableIOMPaymentOptions
        : _site.enableIOMPaymentOptions;

      _site.enablePaypalPaymentOptions = storeConfig?.userData
        ?.enablePaypalPaymentOptions
        ? storeConfig?.userData?.enablePaypalPaymentOptions
        : _site.enablePaypalPaymentOptions;

      _site.enableNET30PaymentOptions = storeConfig?.userData
        ?.enableNET30PaymentOptions
        ? storeConfig?.userData?.enableNET30PaymentOptions
        : _site.enableNET30PaymentOptions;

      /**
       * Deals filter values from the online_store response.
       */
      _site.clearanceFilter = storeConfig?.userData?.clearanceFilter;

      /**
       * Except clearance filter all the other deals filter keys have inconsistent naming pattern
       * therefore these keys are referred from a constants file.
       */
      _site[DEALS_EVERY_DAY_SHIPPING_FILTER] =
        storeConfig?.userData?.[DEALS_EVERY_DAY_SHIPPING_FILTER];
      _site[DEALS_NEW_PRODUCTS] = storeConfig?.userData?.[DEALS_NEW_PRODUCTS];
      _site[DEALS_ON_SALE] = storeConfig?.userData?.[DEALS_ON_SALE];
      _site[DEALS_REBATES_SPECIAL_OFFER] =
        storeConfig?.userData?.[DEALS_REBATES_SPECIAL_OFFER];
      _site[DEALS_ADVANTAGE_EXCLUSIVE] =
        storeConfig?.userData?.[DEALS_ADVANTAGE_EXCLUSIVE];

      _site.collectionsPlpFacetKey = storeConfig?.userData?.collectionsPlpFacetKey;
      _site.promotionPlpFacetKey = storeConfig?.userData?.promotionPlpFacetKey;

      return Promise.resolve(_site as SiteInfo);
    } catch (err) {
      const { response }: AxiosError = err as AxiosError;

      trackUserEvent({
        eventMsg: response,
        eventName: 'tier 1 error',
      });

      throw Promise.reject(err);
    }
  }

  /**
   * @method getStoreData returns store information to contact backend.
   * @param siteInfo Holds store name information to fetch store details from backend.
   * @returns
   */
  private async getStoreData(siteInfo: any | SiteInfo) {
    const requestUrl = `${siteInfo.transactionContext}/store/0/adminLookup`;

    try {
      const request: AxiosRequestConfig = {
        url: requestUrl,
        method: 'GET',
        params: {
          q: 'findByStoreIdentifier',
          storeIdentifier: siteInfo.storeName,
        },
      };

      const storeResponse: IStoreDataResponse = validateIStoreDataResponse(
        await makeUnauthorizedRequest(request)
      );

      return Promise.resolve(storeResponse.resultList[0]);
    } catch (err) {
      const { response }: AxiosError = err as AxiosError;

      trackUserEvent({
        eventMsg: response,
        eventName: 'tier 1 error',
      });

      throw Promise.reject(err);
    }
  }

  /**
   * @method getOnlineStoreData returns online store information to contact backend.
   * @param siteInfo Holds store name information to fetch store details from backend.
   * @returns
   */
  private async getOnlineStoreData(siteInfo: any | SiteInfo) {
    const requestUrl = `${siteInfo.transactionContext}/store/${siteInfo.storeID}/online_store`;

    try {
      const request: AxiosRequestConfig = {
        url: requestUrl,
        method: 'GET',
      };

      const response: IOnlineStoreResponse = validateIOnlineStoreResponse(
        await makeUnauthorizedRequest(request)
      );

      return Promise.resolve(response.resultList[0]);
    } catch (err) {
      const { response }: AxiosError = err as AxiosError;

      trackUserEvent({
        eventMsg: response,
        eventName: 'tier 1 error',
      });

      throw Promise.reject(err);
    }
  }
}
