import {
  ComponentProps,
  ComponentType,
  lazy,
  LazyExoticComponent,
  ReactNode,
} from 'react';
import CmcTemplatesPage from '../components/Pages/CmcTemplatesPage/CmcTemplatesPage';
import * as ROUTES from '../constants/routes';

/**
 * Possible Route guards.
 */
export enum RouteGuards {
  AUTHENTICATED,
  UNAUTHENTICATED,
  COMMON,
}

/**
 * @interface IRoute
 */
export interface IRoute {
  /**
   * route which the app will render
   */
  path: string;

  /**
   * boolean value to decide on wether to match the exact path
   */
  exact?: boolean;

  /**
   * preloader for lazy loading
   */
  fallback?: NonNullable<ReactNode> | null;

  /**
   * lazy loaded component
   */
  component:
  | LazyExoticComponent<ComponentType<ComponentProps<any>>>
  | ComponentType<ComponentProps<any>>;

  /**
   * sub-routes or child routes
   */
  routes?: IRoute[];

  /**
   * redirection path
   */
  redirect?: string;

  /**
   * boolean value for deciding if a route is private or not
   */
  private?: boolean;

  /**
   * Guard to be applied for the route.
   */
  guard: RouteGuards;

  /**
   * Page Type to be sent for Utag Tealium Analyticcs.
   */
  utagPageType?: string;

  /**
   * Page Name to be sent for Utag Tealium Analyticcs.
   */
  utagPageName?: string;

  /**
   * The lazyload behaviour of can be turned off by setting
“visibleByDefault” prop
   */
  visibleByDefault?: boolean;
}

const HomePage = lazy(() => import('../components/Pages/Home/HomePage'));

const CategoryIndexPage = lazy(
  () => import('../components/Pages/CategoryIndexPage/CategoryIndexPage')
);

const BrandIndexPage = lazy(
  () => import('../components/Pages/BrandIndexPage/BrandIndexPage')
);

const AccountDashboardPage = lazy(
  () => import('../components/Pages/AccountDashboardPage/AccountDashboardPage')
);

const OfflinePage = lazy(
  () => import('../components/Pages/OfflinePage/OfflinePage')
);

const SearchPage = lazy(
  () => import('../components/Pages/SearchPage/SearchPage')
);

const RegistrationPage = lazy(
  () =>
    import(
      '../components/Pages/AccountRegistrationPage/AccountRegistrationPage'
    )
);

const SignInPage = lazy(
  () => import('../components/Pages/AccountSignInPage/SignInPage')
);

const ForgotPasswordPage = lazy(
  () => import('../components/Pages/ForgotPasswordPage/ForgotPasswordPage')
);

const MyOrdersGuestPage = lazy(
  () => import('../components/Pages/GuestOrderHistoryPage/GuesOrderHistoryPage')
);

const OrderLookUpPage = lazy(
  () => import('../components/Pages/OrderLookUpPage/OrderLookUpPage')
);

const CheckoutPage = lazy(
  () => import('../components/Pages/CheckoutPage/CheckoutPage')
);

const CheckoutPaymentPage = lazy(
  () =>
    import(
      '../components/Pages/CheckoutPage/CheckoutPaymentPage/CheckoutPaymentPage'
    )
);

const DeliveryPage = lazy(
  () => import('../components/Pages/CheckoutPage/DeliveryPage/DeliveryPage')
);

const ShoppingCartPage = lazy(
  () => import('../components/Pages/ShoppingCartPage/ShoppingCartPage')
);

const OrderReviewPage = lazy(
  () =>
    import('../components/Pages/CheckoutPage/OrderReviewPage/OrderReviewPage')
);

const ErrorPage = lazy(() => import('../components/Pages/ErrorPage/ErrorPage'));

const OrderConfirmationPage = lazy(
  () =>
    import('../components/Pages/OrderConfirmationPage/OrderConfirmationPage')
);

const CatalogOrderPage = lazy(
  () => import('../components/Pages/CatalogOrderPage/CatalogOrderPage')
);

const StoreLocatorPage = lazy(
  () => import('../components/Pages/StaticPages/StoreLocator/StoreLocator')
);

const Seo = lazy(() => import('../components/Seo/Seo'));

const StoreIndex = lazy(
  () => import('../components/Pages/StaticPages/StoreIndex/StoreIndex')
);

const StoreDetail = lazy(
  () =>
    import('../components/Pages/StaticPages/IndividualStore/IndividualStore')
);

const SharedWishlistPage = lazy(
  () => import('../components/Pages/SharedWishlistPage/SharedWishlistPage')
);

const SiteMapListPage = lazy(
  () => import('../components/Pages/StaticPages/SiteMap/SitemapPage')
);

const PlpPage = lazy(() => import('../components/Pages/PlpPage/PlpPage'));

const PaypalProcessingPage = lazy(
  () =>
    import(
      '../components/Pages/CheckoutPage/PaypalProcessingPage/PaypalProcessingPage'
    )
);

const SignInProcessingPage = lazy(
  () => import('../components/Widgets/Forms/SignInForm/SignInProcessingPage')
);

export const routes: IRoute[] = [
  {
    path: ROUTES.HOME,
    component: HomePage,
    guard: RouteGuards.COMMON,
    utagPageType: 'home',
    utagPageName: 'home',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CATEGORY_INDEX,
    component: CategoryIndexPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
    utagPageType: 'categories',
    utagPageName: 'categories | all',
  },
  {
    path: ROUTES.BRAND_INDEX,
    component: BrandIndexPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
    utagPageType: 'brands',
    utagPageName: 'brands | all',
  },
  {
    path: ROUTES.SITE_OFFLINE,
    component: OfflinePage,
    guard: RouteGuards.COMMON,
    utagPageType: 'offline',
    utagPageName: 'page offline',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.REGISTER_USER,
    component: RegistrationPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'register',
    utagPageName: 'register user',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.SIGN_IN,
    component: SignInPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'account',
    utagPageName: 'account | sign-in',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'account',
    utagPageName: 'account | forgot password',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: `${ROUTES.SEARCH_PRODUCT}`,
    component: SearchPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: `${ROUTES.TRACK_ORDER}`,
    component: OrderLookUpPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'order',
    utagPageName: 'order | look-up',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: `${ROUTES.MY_ACCOUNT}`,
    component: AccountDashboardPage,
    guard: RouteGuards.AUTHENTICATED,
    utagPageType: 'account',
    utagPageName: 'account | dashboard',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.GUEST_USER_ORDER_HISTORY,
    component: MyOrdersGuestPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'order history',
    utagPageName: 'guest | order history',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.MY_ACCOUNT_NESTED,
    component: AccountDashboardPage,
    guard: RouteGuards.AUTHENTICATED,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.ERROR_PAGE,
    component: ErrorPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'error',
    utagPageName: 'error page',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.SHOPPING_CART,
    component: ShoppingCartPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'cart',
    utagPageName: 'cart',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CHECKOUT_SIGN_IN,
    component: CheckoutPage,
    guard: RouteGuards.UNAUTHENTICATED,
    utagPageType: 'checkout',
    utagPageName: 'checkout | sign-in',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CHECKOUT_PAYMENT,
    component: CheckoutPaymentPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'checkout',
    utagPageName: 'checkout | payment method',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CHECKOUT_DELIVERY,
    component: DeliveryPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'checkout',
    utagPageName: 'checkout | delivery options',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CHECKOUT_REVIEW,
    component: OrderReviewPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'checkout',
    utagPageName: 'checkout | review',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.ORDER_CONFIRMATION,
    component: OrderConfirmationPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.ORDER_FROM_CATALOG,
    component: CatalogOrderPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'catalog',
    utagPageName: 'order from catalog',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CMC_TEMPLATES,
    component: CmcTemplatesPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.CMC_TEMPLATES,
    component: CmcTemplatesPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.STORE_LOCATOR,
    component: StoreLocatorPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'store',
    utagPageName: 'store | store-locator',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: `${ROUTES.STORE_LOCATOR}/*`,
    component: StoreDetail,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.STORE_INDEX,
    component: StoreIndex,
    guard: RouteGuards.COMMON,
    utagPageType: 'store',
    utagPageName: 'store | index',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.SHARED_WISHLIST,
    component: SharedWishlistPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'account',
    utagPageName: 'account | wish-list',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.SITE_MAP_LIST,
    component: SiteMapListPage,
    guard: RouteGuards.COMMON,
    utagPageType: 'sitemap',
    utagPageName: 'sitemap',
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: `${ROUTES.SHOP_STORE}/*`,
    component: PlpPage,
    guard: RouteGuards.COMMON,
  },
  {
    path: `${ROUTES.SHOP_ALL}/*`,
    component: PlpPage,
    guard: RouteGuards.COMMON,
  },
  {
    path: `${ROUTES.STORE_DETAIL}/*`,
    component: StoreDetail,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.PAYPAL_PROCESSING_PAGE,
    component: PaypalProcessingPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: ROUTES.SINGIN_PROCESSING_PAGE,
    component: SignInProcessingPage,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
  {
    path: '/*',
    component: Seo,
    guard: RouteGuards.COMMON,
    visibleByDefault: (window as any).__isPrerender__ || false,
  },
];
